<template>
  <div>
    <b-nav id="left_sidebar" vertical className="w-25">
      <span v-for="(menu,key) in menus" :key="key">
        <b-nav-item style="background-image: image('font_left_nav.png);" v-for="(sub_menu,key2) in menu.public_sub_menus" :key="key2">
          <router-link :to="{name:'common.page',params:{menu:menu.name,slug:sub_menu.slug}}">
            <strong>{{ sub_menu.name }}</strong>
          </router-link>
        </b-nav-item>
      </span>
    </b-nav>
  </div>

</template>
<script>
import {
  BNav,
} from 'bootstrap-vue'

export default {
  name: 'header',
  components: {
    BNav,
  },
  data() {
    return {}
  },
  methods: {},
  created() {
    if(this.menus.length <1) this.$store.dispatch('GET_ALL_MENU');
  },
  computed:{
    menus() {
      return this.$store.getters.menusRight;
    },
  }
}
</script>
<style scoped>

#left_sidebar li a {
  background: linear-gradient(180deg, rgba(40,75,25) 0%, rgba(255, 160, 122) 100%);
  color: #0c0c0c !important;
  padding: 5px 14px !important;
  border-radius: 2%;
  text-align: center;
}

</style>